<template>
    <r-e-dialog title="发起审批" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh"
                width="1300px" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="130px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="房间核验码" prop="housingCode">
                    <el-input v-model="formPublish.housingCode" placeholder="请输入房间核验码" :disabled="true"
                              style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="系统合同号" prop="contractNo">
                    <el-input v-model="formPublish.contractNo" placeholder="请输入系统合同号" :disabled="true"
                              style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="房屋坐落" prop="location">
                    <el-input v-model="formPublish.location" placeholder="请输入房屋坐落" style="width: 280px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="押金" prop="deposit">
                    <el-input v-model="formPublish.deposit" placeholder="请输入押金" :disabled="true"
                              style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="支付方式 " prop="payType">
                    <el-select v-model="formPublish.payType" placeholder="请选择支付方式" style="width: 280px;">
                        <el-option label="年" :value="1"/>
                        <el-option label="季度" :value="2"/>
                        <el-option label="月" :value="3"/>
                        <el-option label="天" :value="4"/>
                        <el-option label="半年" :value="5"/>
                        <el-option label="其他" :value="6"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="支付方式" prop="qtzffs">
                    <el-input v-model="formPublish.qtzffs" placeholder="请输入按月支付方式月数" style="width: 280px;"
                              :disabled="isInputDisabled"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="出租方式" prop="rentType">
                    <el-select v-model="formPublish.rentType" placeholder="请选择出租方式(整租/分组)" style="width: 280px;">
                        <el-option label="整租" value="整租"/>
                        <el-option label="分组" value="分组"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="出租房间名称 " prop="roomName">
                    <el-input v-model="formPublish.roomName" placeholder="请输入出租房间名称" style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="出租房间编号">
                    <el-input v-model="formPublish.roomNum" placeholder="请输入出租房间编号" style="width: 280px;"
                              :disabled="formPublish.rentType==='整租'"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="租赁面积" prop="rentArea">
                    <el-input v-model="formPublish.rentArea" placeholder="请输入租赁面积" :disabled="true"
                              style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="网签备注说明 " prop="remark">
                    <el-input v-model="formPublish.remark" placeholder="请输入网签备注说明" style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="租金" prop="monthlyRent">
                    <el-input v-model="formPublish.monthlyRent" placeholder="请输入租金" :disabled="true"
                              style="width: 280px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="出租开始时间" prop="rentStartTime">
                    <el-date-picker v-model="formPublish.rentStartTime" value-format="yyyy-MM-dd" :disabled="true"
                                    style="width: 280px;" placeholder="请选择出租开始时间"/>
                </el-form-item>
                <el-form-item label="出租终止时间 " prop="rentEndTime">
                    <el-date-picker v-model="formPublish.rentEndTime" value-format="yyyy-MM-dd" :disabled="true"
                                    style="width: 280px;" placeholder="请选择出租终止时间"/>
                </el-form-item>
                <el-form-item label="经纪人姓名" prop="brokerName">
                    <el-input v-model="formPublish.brokerName" placeholder="请输入经纪人姓名" style="width: 280px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="经纪人身份证号" prop="brokerID">
                    <el-input v-model="formPublish.brokerID" placeholder="请输入经纪人身份证号" style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="中介机构名称" prop="intermediaryName">
                    <el-input v-model="formPublish.intermediaryName" placeholder="请输入中介机构名称" style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="修改业务编号" prop="businessNo">
                    <el-input v-model="formPublish.businessNo" placeholder="请输入修改业务编号" :disabled="true"
                              style="width: 280px;"/>
                </el-form-item>
            </div>
        </el-form>
        <!-- 出租人信息 -->
        <div class="title">出租人信息</div>
        <el-form ref="lessors" label-width="130px" size="small" :model="lessors" :rules="rulesLessors">
            <div class="flex">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="lessors.name" placeholder="请输入出租人姓名" style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-select v-model="lessors.type" placeholder="请选择出租人类型(个人/单位)" style="width: 280px;">
                        <el-option label="个人" value="个人"/>
                        <el-option label="单位" value="单位"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="证件类型 " prop="certificateType">
                    <el-select v-model="lessors.certificateType" placeholder="请选择出租人证件类型" style="width: 280px;">
                        <el-option label="居民身份证" value="居民身份证"/>
                        <el-option label="事业单位法人证书" value="事业单位法人证书"/>
                        <el-option label="港澳台通行证" value="港澳台通行证"/>
                        <el-option label="港澳台身份证" value="港澳台身份证"/>
                        <el-option label="往来大陆通行证" value="往来大陆通行证"/>
                        <el-option label="护照" value="护照"/>
                        <el-option label="户口簿" value="户口簿"/>
                        <el-option label="军官证（士兵证）" value="军官证（士兵证）"/>
                        <el-option label="组织机构代码证" value="组织机构代码证"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="lessors.phone" placeholder="请输入出租人手机号" style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="证件号" prop="id">
                    <el-input v-model="lessors.id" placeholder="请输入出租人证件号" style="width: 280px;"/>
                </el-form-item>
            </div>
        </el-form>

        <!-- 承租人信息 -->
        <div class="title">承租人信息</div>
        <el-form ref="renters" label-width="130px" size="small" :model="renters" :rules="rulesLessors">
            <div class="flex">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="renters.name" placeholder="请输入承租人姓名" style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-select v-model="renters.type" placeholder="请选择出租人类型(个人/单位)" style="width: 280px;">
                        <el-option label="个人" value="个人"/>
                        <el-option label="单位" value="单位"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="证件类型 " prop="certificateType">
                    <el-select v-model="renters.certificateType" placeholder="请选择出租人证件类型" style="width: 280px;">
                        <el-option label="居民身份证" value="居民身份证"/>
                        <el-option label="事业单位法人证书" value="事业单位法人证书"/>
                        <el-option label="港澳台通行证" value="港澳台通行证"/>
                        <el-option label="港澳台身份证" value="港澳台身份证"/>
                        <el-option label="往来大陆通行证" value="往来大陆通行证"/>
                        <el-option label="护照" value="护照"/>
                        <el-option label="户口簿" value="户口簿"/>
                        <el-option label="军官证（士兵证）" value="军官证（士兵证）"/>
                        <el-option label="组织机构代码证" value="组织机构代码证"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="renters.phone" placeholder="请输入承租人手机号" style="width: 280px;"/>
                </el-form-item>
                <el-form-item label="证件号" prop="id">
                    <el-input v-model="renters.id" placeholder="请输入承租人证件号" style="width: 280px;"/>
                </el-form-item>
            </div>
        </el-form>
        <!--扫描件信息 -->
        <div class="title">扫描件信息</div>
        <el-form label-width="130px">
            <div class="flex">
                <el-form-item label="pdf附件">
                    <div class="flex">
                        <div v-for="(item, index) in filePDFList" style="width: 100px;" :key="index">
                            <div style="line-height: 30px;height: 30px;text-align: center">
                                <i class="el-icon-document" style="font-size: 30px"/>
                            </div>
                            <div style="height: 20px;line-height: 20px;text-align: center">{{ item.scanName }}</div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="照片" label-width="70px">
                    <upload-picture-card :limit="100" :uuid-list="imgList" :show-tip="false" :key="imgList.length"
                                         :show-add="false" @on-remove="handleRemove"/>
                </el-form-item>
                <el-form-item label-width="70px">
                    <el-button type="primary" v-if="!isShowAddImg" @click="addImg" size="small">添加照片</el-button>
                </el-form-item>
            </div>
        </el-form>
        <div v-if="isShowAddImg">
            <el-form ref="scans" label-width="120px" size="small" :model="scans" :rules="scansRules">
                <div class="flex">
                    <el-form-item label="图片" prop="uuid">
                        <upload-avatar @on-success="uploadAvatarSuccess" :uuid="scans.uuid"/>
                    </el-form-item>
                    <el-form-item label="文件名" prop="scanName" label-width="70px">
                        <el-input v-model="scans.scanName" placeholder="请输入文件名" style="width: 280px;"/>
                    </el-form-item>
                    <el-form-item label-width="70px">
                        <el-button type="primary" @click="saveImg" size="small">确定</el-button>
                        <el-button @click="quxiaoSave" size="small">取消</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </div>
    </r-e-dialog>
</template>

<script>
import uploadPictureCard from "@/components/Upload/upload-picture-card";
import uploadAvatar from "@/components/Upload/upload-avatar";
import {getContractRegisteredInfo, uploadContractInfo, getContractRegisteredInfoByLast} from "@/api/contract"
import {MessageSuccess, MessageWarning} from "@custom/message";

export default {
    name: "dialogApproval",
    data() {
        return {
            dialogVisible: false, // 弹窗
            contractUuid: null,
            formPublish: {
                housingCode: null, // 	房间核验码
                contractNo: null, // 系统合同合同号
                location: null, // 房屋坐落
                deposit: null, // 房屋坐落
                payType: null, // 支付方式 年1 季度2 月3 天4 半年5 其他6
                qtzffs: null, // 支付方式选择其他需要填写数字具体是按月支付
                rentType: null, // 出租方式 整租 分租
                roomName: null,// 出租房间名称
                roomNum: null,// 出租房间编号
                rentArea: null, // 租赁面积 要与租赁部位对应
                remark: null,// 网签的备注说明
                monthlyRent: null, // 	租金
                rentStartTime: null, // 出租开始时间 YYYY-MM-DD
                rentEndTime: null, // 出租终止时间 YYYY-MM-DD
                brokerName: null,// 经纪人/个人姓名
                brokerID: null, // 经纪人/个人身份证号
                intermediaryName: null, // 中介机构名称
                businessNo: null, // 修改业务编号 通过接口03查询的信息内有 审核不通过重新申请时传入
            },
            lessors: {
                name: null,//名称
                type: null,//个人、单位
                certificateType: null,//证件类型
                id: null,// 证件号
                phone: null// 手机号
            },// 出租人信息
            renters: {
                name: null,//名称
                type: null,//个人、单位
                certificateType: null,//证件类型
                id: null,// 证件号
                phone: null// 手机号
            }, // 承租人信息
            rules: {
                housingCode: [{required: true, message: '请输入房间核验码', trigger: 'blur'}],
                contractNo: [{required: true, message: '请输入系统合同合同号', trigger: 'blur'}],
                location: [{required: true, message: '请输入房屋坐落', trigger: 'blur'}],
                deposit: [{required: true, message: '请输入房屋坐落', trigger: 'blur'}],
                payType: [{required: true, message: '请选择支付方式', trigger: 'change'}],
                rentType: [{required: true, message: '请选择出租方式', trigger: 'change'}],
                roomName: [{required: true, message: '请输入出租房间名称', trigger: 'blur'}],
                roomNum: [{required: true, message: '请输入出租房间编号', trigger: 'blur'}],
                rentArea: [{required: true, message: '请输入租赁面积', trigger: 'blur'}],
                monthlyRent: [{required: true, message: '请输入租金', trigger: 'blur'}],
                rentStartTime: [{required: true, message: '请选择出租开始时间', trigger: 'change'}],
                rentEndTime: [{required: true, message: '请选择出租终止时间', trigger: 'change'}],
                brokerName: [{required: true, message: '请输入经纪人姓名', trigger: 'blur'}],
                brokerID: [{required: true, message: '请输入经纪人身份证号', trigger: 'blur'}],
                intermediaryName: [{required: true, message: '请输入中介机构名称', trigger: 'blur'}],
                // businessNo: [{required: true, message: '请输入修改业务编号', trigger: 'blur'}],
            },
            rulesLessors: {
                name: [{required: true, message: '请输入房间核验码', trigger: 'blur'}],
                type: [{required: true, message: '请选择类型', trigger: 'change'}],
                certificateType: [{required: true, message: '请选择证件类型', trigger: 'change'}],
                id: [{required: true, message: '请输入证件号', trigger: 'blur'}],
                phone: [{required: true, message: '请输入手机号', trigger: 'blur'}],
            },
            scansRules: {
                uuid: [{required: true, message: '请上传图片', trigger: 'blur'}],
                scanName: [{required: true, message: '请输入文件名(带后缀格式)', trigger: 'blur'}],
            },
            filePDFList: [],
            imgList: [],
            fileImgList: [],
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            scans: {scanName: null, scanData: null, uuid: null},
            isShowAddImg: false,
        };
    },
    props: {
        currentHouse: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        // 判断用户是否选择的是月
        isInputDisabled() {
            return this.formPublish.payType !== 6;
        }
    },
    components: {
        uploadPictureCard,
        uploadAvatar,
    },
    methods: {
        // 打开合同备案弹窗
        async openDialog(uuid, type) {
            let that = this;
            this.contractUuid = uuid;
            const {loadingOptions} = that;
            const loading = that.$loading(loadingOptions);
            //判断类型 type=0，通过合同uuid获取合同备案信息，type=1，通过备案记录uuid获取获取合同备案信息
            if (type === 0) {
                // 调用接口，通过合同uuid获取合同备案信息
                const res = await getContractRegisteredInfo({contractUuid: uuid});
                //判断信息是否存在
                if (res) {
                    //解构数据
                    const {info} = res;
                    const {
                        applyID, brokerID, brokerName, businessNo, contractNo, deposit, housingCode, intermediaryName,
                        location, monthlyRent, payType, qtzffs, remark, rentArea, rentEndTime, rentStartTime, rentType,
                        roomName, roomNum
                    } = info;

                    //保存旧数据
                    const oldFormPublish = that.formPublish;
                    //拆分旧数据以免字段被移除，打包合同备案信息，并赋值
                    that.formPublish = {
                        ...oldFormPublish, applyID, brokerID, brokerName, businessNo, contractNo, deposit, housingCode,
                        intermediaryName, location, monthlyRent, payType: Number(payType), qtzffs, remark, rentArea,
                        rentEndTime, rentStartTime, rentType, roomName, roomNum
                    }
                    // 解构出租人信息、承租人信息、备案文件信息
                    const {lessors, renters, scans} = info;
                    //拆分备案文件，第一个必定是PDF文件，其他都是图片文件
                    const [pdfData, ...ImgData] = scans;
                    // PDF文件赋值保存
                    that.filePDFList = [pdfData];
                    // 图片文件赋值保存
                    that.fileImgList = ImgData;
                    // 图片文件数组抽取uuid，并赋值图片数组，用于展示图片
                    that.imgList = that.fileImgList.map(({uuid}) => uuid);
                    // 保存旧出租人信息
                    const oldLessors = this.lessors;
                    //拆分旧数据以免字段被移除，拆分出租人信息，打包并赋值
                    that.lessors = {...oldLessors, ...lessors[0]}
                    // 保存旧承租人信息
                    const oldRenters = this.renters;
                    //拆分旧数据以免字段被移除，拆分承租人信息，打包并赋值
                    that.renters = {...oldRenters, ...renters[0]}
                }
            }
            //判断类型 type=0，通过合同uuid获取合同备案信息，type=1，通过备案记录uuid获取获取合同备案信息
            if (type === 1) {
                // 调用接口，通过备案记录uuid获取合同备案信息
                const res = await getContractRegisteredInfoByLast({uuid});
                //判断信息是否存在
                if (res) {
                    //解构数据
                    const {info} = res;
                    const {
                        applyID, brokerID, brokerName, businessNo, contractNo, deposit, housingCode, intermediaryName,
                        location, monthlyRent, payType, qtzffs, remark, rentArea, rentEndTime, rentStartTime, rentType,
                        roomName, roomNum
                    } = info;

                    //保存旧数据
                    const oldFormPublish = that.formPublish;
                    //拆分旧数据以免字段被移除，打包合同备案信息，并赋值
                    that.formPublish = {
                        ...oldFormPublish, applyID, brokerID, brokerName, businessNo, contractNo, deposit, housingCode,
                        intermediaryName, location, monthlyRent, payType: Number(payType), qtzffs, remark, rentArea,
                        rentEndTime, rentStartTime, rentType, roomName, roomNum
                    }
                    // 解构出租人信息、承租人信息、备案文件信息
                    const {lessors, renters, scans} = info;
                    //拆分备案文件，第一个必定是PDF文件，其他都是图片文件
                    const [pdfData, ...ImgData] = scans;
                    // PDF文件赋值保存
                    that.filePDFList = [pdfData];
                    // 图片文件赋值保存
                    that.fileImgList = ImgData;
                    // 图片文件数组抽取uuid，并赋值图片数组，用于展示图片
                    that.imgList = that.fileImgList.map(({uuid}) => uuid);
                    // 保存旧出租人信息
                    const oldLessors = this.lessors;
                    //拆分旧数据以免字段被移除，拆分出租人信息，打包并赋值
                    that.lessors = {...oldLessors, ...lessors[0]}
                    // 保存旧承租人信息
                    const oldRenters = this.renters;
                    //拆分旧数据以免字段被移除，拆分承租人信息，打包并赋值
                    that.renters = {...oldRenters, ...renters[0]}
                }
            }
            loading.close();
            //打开弹窗
            this.dialogVisible = true;
        },

        // 点击弹框确认事件
        clickSubmit() {
            let that = this;
            //表单校验，校验合同备案信息
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    //表单校验，校验出租人信息
                    that.$refs["lessors"].validate((valid) => {
                        if (valid) {
                            //表单校验，校验承租人信息
                            that.$refs["renters"].validate((valid) => {
                                if (valid) {
                                    //调用合同备案信息提交方法
                                    that.uploadContractInfo();
                                }
                            });
                        }
                    });
                }
            });
        },

        //合同备案信息提交方法
        uploadContractInfo() {
            const that = this;
            // 解构合同备案信息
            const {
                housingCode, contractNo, location, deposit, payType, qtzffs, rentType, roomName, roomNum, rentArea,
                remark, monthlyRent, rentStartTime, rentEndTime, brokerName, brokerID, intermediaryName, applyID,
                businessNo
            } = that.formPublish;
            // 获取出租人信息承租人信息
            const lessors = [that.lessors];
            const renters = [that.renters];
            // 获取合同备案文件信息
            let scans = [];
            scans = that.fileImgList || [];
            //封装数据
            const params = {
                housingCode, contractNo, location, deposit, payType, qtzffs, rentType, roomName, roomNum, rentArea,
                remark, monthlyRent, rentStartTime, rentEndTime, brokerName, brokerID, intermediaryName, applyID,
                businessNo, lessors, renters, scans
            }
            const {loadingOptions} = that;
            const loading = that.$loading(loadingOptions);
            //调用接口发起合同备案审核
            uploadContractInfo(params).then(res => {
                MessageSuccess('提交合同备案成功');
                that.clickCancel();
            }).finally(() => loading.close());
        },

        // 点击弹框取消事件
        clickCancel() {
            //关闭弹窗
            this.dialogVisible = false;
            //清空合同备案文件
            this.filePDFList = [];
            this.imgList = [];
            this.fileImgList = [];
            //重置数据
            this.$refs["formPublish"].resetFields();
            this.$refs["lessors"].resetFields();
            this.$refs["renters"].resetFields();
            //调用上层方法刷新数据
            this.$emit('handleSearch');
        },
        // 点击添加图片事件
        addImg() {
            //图片文件对象重置
            this.scans.uuid = null;
            this.scans.scanName = null;
            this.scans.scanData = null;
            //显示图片上传
            this.isShowAddImg = true;
        },
        // 点击保存图片事件
        saveImg() {
            //校验图片信息
            this.$refs["scans"].validate((valid) => {
                if (valid) {
                    //获取图片文件对象
                    const scans = this.scans;
                    //解构数据
                    const {scanData, scanName, uuid} = scans;

                    //判断文件格式是否正确
                    const acceptArray = '.jpg,.jpeg,.png,.gif'.split(",");
                    const extensionIndex = scanName.lastIndexOf(".");       // 后缀序列
                    const extension = scanName.substring(extensionIndex);   // 文件后缀
                    let isFileType = acceptArray.includes(extension);
                    if (!isFileType) {
                        MessageWarning(`请上传${'.jpg,.jpeg,.png,.gif'}格式的文件！`);
                        return
                    }

                    //添加合同文件到到合同文件数组
                    this.fileImgList.push({scanData, scanName, uuid});
                    // 合同文件数组抽取uuid，并赋值图片数组，用于展示图片
                    this.imgList = this.fileImgList.map(({uuid}) => uuid);
                    //调用取消保存方法，关闭文件上传
                    this.quxiaoSave();
                }
            });
        },

        //取消保存方法，关闭文件上传
        quxiaoSave() {
            //清空合同文件数据
            this.scans.uuid = null;
            this.scans.scanName = null;
            this.scans.scanData = null;
            //关闭文件上传
            this.isShowAddImg = false;
        },

        //删除合同文件方法
        handleRemove({fileList}) {
            // 获取删除后的文件上传数组，抽取uuid，并赋值图片数组，用于展示图片
            this.imgList = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
            // 通过展示图片uuid数组，过滤非上传文件
            this.fileImgList = this.fileImgList.filter(({uuid}) => this.imgList.includes(uuid));
        },

        //合同文件上传成功回调方法
        uploadAvatarSuccess({info}) {
            //获取上传文件uuid，保存给文件对象uuid
            this.scans.uuid = info.uuid;
            //清空表单校验
            this.$refs["scans"].validateField('uuid');
        },
    }
}
</script>

<style scoped>
.title {
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
}

.title::before {
    width: 5px;
    height: 100%;
    content: '';
    background-color: #F5A623;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10px;
}
</style>